<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container mt-5">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item>Product Groups</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>

          <h3><b>Product Groups:</b></h3>
          <div
            style="display: flex; align-items:center; justify-content: space-between;"
            class="search_add_section"
          >
            <div style="width: 50%;">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by Name"
                class="search_by_input form-control"
                spellcheck="off"
              />
            </div>
            <div>
              <AddProductGroupDialog @re-fresh="getProductGroups" />
            </div>
          </div>
          <hr />

          <div
            v-loading="loading || is_changing"
            class="table-responsive mb-5"
          >
            <data-tables
              :data="product_groups"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="isLoading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getProductGroups"
                  v-else-if="!isLoading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Product Groups Now. Please click here to retry
                </div>
                <div v-if="!isLoading && !loadingError">No Product Groups</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Image"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                width="75px"
              >
                <template slot-scope="scope">
                  <img
                    :src="scope.row.product_group_image"
                    style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                  >
                </template>
              </el-table-column>

              <el-table-column
                label="Product Group Name"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <span> {{ scope.row.product_group_name }} </span><br />
                  <small>Add On: {{ formattedDate(scope.row.created_date) }} </small><br />
                  {{ scope.row.status_priority }}
                </template>
              </el-table-column>

              <el-table-column
                label="Group Priority"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  {{ scope.row.status_priority }}
                  <el-select
                    v-model="scope.row.status_priority"
                    placeholder="Select"
                    size="mini"
                    @change="changePriority(scope.row.product_group_id, scope.row.status_priority)"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column
                label="Products"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="warning"
                    :disabled="false"
                    round
                  >{{ scope.row.number_of_products }} Products</el-button>
                </template>
              </el-table-column>

              <el-table-column
                label="Status"
                label-class-name="text-center"
                class-name="text-center"
                width="100px"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex;">
                    <el-tooltip
                      :content="scope.row.is_active ? 'Active' : 'Deactivated'"
                      placement="top"
                    >
                      <el-switch
                        v-model="scope.row.is_active"
                        @change="switchStatus(scope.row.is_active, scope.row.product_group_id)"
                        active-color="#13ce66"
                        inactive-color=""
                      >
                      </el-switch>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Edit"
                      placement="top"
                    >
                      <el-button
                        type="warning"
                        :disabled="$store.state.userRoles !== 'ADMIN'"
                        @click="showEditProductGroupData(scope.row)"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        :disabled="$store.state.userRoles !== 'ADMIN'"
                        @click="deleteProductGroup(scope.row.product_group_id)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

    <EditProductGroupDialog
      :showProductGroupEditDialog="showProductGroupEditDialog"
      :productGroupData="productGroup_data"
      @closeEditProductGroupDialog="closeEditProductGroupData"
      @re-fresh="getProductGroups"
    />

  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import AddProductGroupDialog from "../components/dialogs/add-product-group-dialog.vue";
import EditProductGroupDialog from "../components/dialogs/edit-product-group-dialog";
import { format, parseISO } from "date-fns";
export default {
  components: {
    ScaleOut,
    AddProductGroupDialog,
    EditProductGroupDialog,
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      is_changing: false,
      showProductGroupEditDialog: false,
      product_groups: [],
      productGroup_data: {},
      decoded_token: {},
      decoded_token2: {},
      first_name: "",

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "fullname",
          value: "",
        },
      ],
      loadingError: false,

      options: [
        {
          value: "one",
          label: "First Priority",
        },
        {
          value: "two",
          label: "Second Priority",
        },
        {
          value: "three",
          label: "Third Priority",
        },
        {
          value: "others",
          label: "Others",
        },
      ],
      product_group_priority: "",
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getProductGroups();
  },

  methods: {
    showEditProductGroupData(productGroupData) {
      this.showProductGroupEditDialog = true;
      this.productGroup_data = productGroupData;
    },

    closeEditProductGroupData() {
      this.showProductGroupEditDialog = false;
    },

    async getProductGroups() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`product-groups`);
        if (request.data.success) {
          this.product_groups = request.data.groups;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Product groups now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async deleteProductGroup(product_group_id) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Product Group. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(
              `product-groups/${product_group_id}`
            );
            if (
              request.data.success &&
              request.data.message === "PRODUCT_GROUP_DELETED_SUCCESSFULLY"
            ) {
              this.getProductGroups();
              this.$notify({
                title: "Success",
                message: "Product Group deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete the Product Group, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },

    async changePriority(product_group_id, status_priority) {
      // alert("product_group_id"+product_group_id);
      // alert('status_priority)'+status_priority);
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.patch(
          `product-groups/assign-priority-status/${product_group_id}`,
          {
            status_priority: status_priority,
          }
        );
        if (
          request.data.success &&
          request.data.message === "ASSIGNED_PRIORITY_SUCCESSFULLY"
        ) {
          this.$notify({
            title: "Success",
            message: "Assigned Priority successfully",
            type: "success",
          });
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Product groups now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async switchStatus(activation_status, product_group_id) {
      try {
        this.is_changing = true;
        let request = await this.$http.patch(
          `product-groups/change_is_active_status/${product_group_id}`, {
            is_active: activation_status,
          }
        );
        if (
          request.data.success &&
          request.data.message == "PRODUCT_GROUP_IS_ACTIVE_STATUS_CHANGED_SUCCESSFULLY"
        ) {
          this.getProductGroups();
          this.showSuccessMessage("Success", );
              return this.$notify({
                title: "Success",
                message: "Product Group status changed",
                type: "success",
              });
        } else {
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.is_changing = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to change Product Group status",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.is_changing = false;
      }
    },

  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>